.study-guide-container {
    width: 100%;
    max-width: 600px;
    padding: var(--padding);
    background-color: var(--background-color);
    border-radius: var(--border-radius);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: var(--margin) auto;
    color: var(--text-color);
    box-sizing: border-box;
    padding-bottom: 80px; /* Add padding to prevent content cutoff */
}

.back-button {
    display: inline-block;
    margin-bottom: var(--margin);
    padding: 0.5rem 1rem;
    background-color: var(--button-background-color);
    color: var(--button-text-color);
    text-decoration: none;
    font-weight: bold;
    border-radius: var(--border-radius);
    transition: background-color 0.3s, transform 0.2s;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.back-button:hover {
    background-color: var(--link-color);
    color: var(--background-color);
    transform: scale(1.05);
}

.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
    margin-bottom: var(--margin);
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.markdown-list {
    list-style-type: disc;
    padding-left: 1.5rem; /* Indent list items */
    margin-bottom: var(--margin);
    line-height: 1.6; /* Increase line spacing */
    font-size: 1rem; /* Adjust font size for readability */
}

.markdown-list-item {
    margin-bottom: 0.5rem; /* Add space between items */
}

.markdown-paragraph {
    margin-bottom: var(--margin);
}

strong {
    font-weight: bold;
}

@media (max-width: 600px) {
    .markdown-list {
        font-size: 0.9rem; /* Smaller font size for mobile */
        padding-left: 1rem; /* Adjust padding for smaller screens */
    }

    .markdown-list-item {
        margin-bottom: 0.4rem; /* Adjust spacing for mobile */
    }
}