.manage-members-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
}

.member-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}

.add-member-section {
    margin-top: 20px;
}

.add-member-section h2 {
    margin-bottom: 10px;
}

.add-member-section button {
    margin-top: 10px;
}