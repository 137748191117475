.study-container {
    width: 100%;
    max-width: 600px;
    padding: var(--padding);
    background-color: var(--background-color);
    border-radius: var(--border-radius);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: var(--margin) auto;
    color: var(--text-color);
}

.study-container h2 {
    margin: var(--margin) 0;
    color: var(--link-color);
    font-size: var(--font-size-large);
    text-align: center;
    font-weight: bold;
}

.study-content {
    margin-top: var(--margin);
    display: flex;
    flex-direction: column;
    gap: var(--margin);
}

.study-content div {
    display: flex;
    justify-content: center;
}

.study-content a {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    background-color: var(--button-background-color);
    color: var(--button-text-color);
    text-decoration: none;
    font-weight: bold;
    border-radius: var(--border-radius);
    transition: background-color 0.3s, transform 0.2s;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.study-content a:hover {
    background-color: var(--link-color);
    color: var(--background-color);
    transform: scale(1.05);
}

@media (max-width: 600px) {
    .study-container h2 {
        font-size: var(--font-size-large);
    }

    .study-content a {
        font-size: var(--font-size-small);
        padding: 0.5rem 1rem;
    }
}