.edit-study-guides-container {
    padding: var(--padding);
    max-width: 800px;
    margin: 0 auto;
    background-color: var(--header-background-color);
    border-radius: var(--border-radius);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: var(--text-color);
}

.edit-study-guides-container h2 {
    text-align: center;
    color: var(--link-color);
    margin-bottom: var(--margin);
    font-size: var(--font-size-large);
    font-weight: bold;
}

.edit-study-guides-container ul {
    list-style-type: none;
    padding: 0;
}

.edit-study-guides-container li {
    margin: var(--margin) 0;
    padding: var(--padding);
    background-color: var(--input-background-color);
    border-radius: var(--border-radius);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.edit-study-guides-container li:hover {
    background-color: #3c3c3c; /* Slightly lighter on hover */
}

.edit-study-guides-container a {
    text-decoration: none;
    color: var(--link-color);
    font-weight: bold;
}

.edit-study-guides-container a:hover {
    text-decoration: underline;
}

.edit-study-guides-container button {
    background-color: var(--link-color);
    color: var(--header-background-color);
    border: none;
    border-radius: var(--border-radius);
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;
}

.edit-study-guides-container button:hover {
    background-color: var(--link-hover-color);
}

.back-button {
    display: inline-block;
    margin-top: var(--margin);
    padding: 10px 20px;
    background-color: var(--link-color);
    color: var(--header-background-color);
    text-align: center;
    border-radius: var(--border-radius);
    text-decoration: none;
    transition: background-color 0.3s;
    font-weight: bold;
}

.back-button:hover {
    background-color: var(--link-hover-color);
}

@media (max-width: 600px) {
    .edit-study-guides-container h2 {
        font-size: var(--font-size-large);
    }

    .edit-study-guides-container button {
        font-size: var(--font-size-small);
        padding: 0.5rem 1rem;
    }
}