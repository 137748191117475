:root {
  --background-color: #ffffff;
  --header-background-color: #f2f2f7;
  --text-color: #1c1c1e;
  --link-color: #007aff;
  --link-hover-color: #0056b3;
  --input-background-color: #f2f2f7;
  --input-text-color: #1c1c1e;
  --button-background-color: #007aff;
  --button-text-color: #ffffff;
  --border-radius: 16px;
  --padding: 16px;
  --margin: 16px;
  --font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --font-size: 16px;
  --font-size-small: 14px;
  --font-size-large: 18px;
}

body.dark-mode {
  --background-color: #000000;
  --header-background-color: #1c1c1e;
  --text-color: #ffffff;
  --link-color: #0a84ff;
  --link-hover-color: #66aaff;
  --input-background-color: #2c2c2e;
  --input-text-color: #ffffff;
  --button-background-color: #0a84ff;
  --button-text-color: #ffffff;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: var(--font-size);
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--padding);
  min-height: 100vh;
  max-width: 100%;
}

main {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
}

.App-header {
  background-color: var(--header-background-color);
  width: 100%;
  padding: 12px;
  text-align: center;
  border-radius: var(--border-radius);
  margin-bottom: var(--margin);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
  margin: 0;
  font-weight: bold;
  font-size: var(--font-size-large);
}

.theme-toggle {
  background: var(--button-background-color);
  border: none;
  border-radius: var(--border-radius);
  padding: 8px 16px;
  color: var(--button-text-color);
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.theme-toggle:hover {
  background-color: var(--link-hover-color);
}

.bottom-nav {
  width: 100%;
  background-color: var(--header-background-color);
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  padding-bottom: 20px;
}

.bottom-nav ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin: 0;
}

.bottom-nav ul li a {
  color: var(--text-color);
  text-decoration: none;
  font-size: 24px;
  transition: color 0.3s, background-color 0.3s;
  padding: 10px;
  border-radius: 12px;
}

.bottom-nav ul li a:hover {
  color: var(--link-hover-color);
}

.bottom-nav ul li a.active {
  background-color: rgba(0, 122, 255, 0.1); /* Light blue background */
  color: var(--link-color); /* Consistent text color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}