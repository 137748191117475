.admin-container {
    width: 100%;
    max-width: 600px;
    padding: var(--padding);
    background-color: var(--background-color);
    border-radius: var(--border-radius);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: var(--margin) auto;
    color: var(--text-color);
    text-align: center;
}

.admin-container h1 {
    margin-bottom: var(--margin);
    color: var(--link-color);
    font-size: var(--font-size-large);
    font-weight: bold;
}

.admin-container p {
    margin-bottom: var(--margin);
    font-size: var(--font-size);
}

   /* src/css/AdminPage.css */
   .admin-container button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin: 10px 0;
    width: 100%;
  }

  .admin-container button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }

  .admin-container button:active {
    background-color: #004494;
    transform: translateY(0);
  }

@media (max-width: 600px) {
    .admin-container h1 {
        font-size: var(--font-size-large);
    }

    .admin-container button {
        font-size: var(--font-size-small);
        padding: 0.5rem 1rem;
    }
}