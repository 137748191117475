/* src/css/GroupSetupWizard.css */

.group-setup-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--background-color);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: var(--text-color);
}

.group-setup-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group textarea {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    background-color: var(--input-background-color);
    color: var(--input-text-color);
}

.form-group textarea {
    resize: vertical;
    height: 100px;
}

.form-group input[type="checkbox"] {
    margin-right: 10px;
}

button {
    width: 100%;
    padding: 10px;
    background-color: #007aff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #005bb5;
}

/* Dark and Light Mode Variables */
:root {
    --background-color: #f9f9f9;
    --text-color: #333;
    --input-background-color: #fff;
    --input-text-color: #333;
}

body.dark-mode {
    --background-color: #333;
    --text-color: #f9f9f9;
    --input-background-color: #444;
    --input-text-color: #f9f9f9;
}