.sign-up-container {
    width: 100%;
    max-width: 600px;
    padding: 1rem;
    background-color: var(--background-color);
    border-radius: var(--border-radius);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 1rem auto;
    color: var(--text-color);
    box-sizing: border-box;
}

.sign-up-container h2, .sign-up-container h3 {
    margin: 0.5rem 0;
    color: var(--text-color);
    font-size: 1.5rem;
    text-align: center;
    font-weight: bold;
}

.sign-up-container input, .sign-up-container button {
    width: 100%;
    margin: 0.5rem 0;
    padding: 0.75rem;
    font-size: 1rem;
    background-color: var(--input-background-color);
    color: var(--input-text-color);
    border: none;
    border-radius: var(--border-radius);
    box-sizing: border-box;
}

.sign-up-container button {
    background-color: var(--button-background-color);
    color: var(--button-text-color);
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s;
}

.sign-up-container button:hover {
    background-color: var(--link-hover-color);
    color: var(--background-color);
}

.sign-up-container ul {
    list-style: none;
    padding: 0;
    width: 100%;
}

.sign-up-container li {
    background-color: var(--input-background-color);
    padding: 0.75rem;
    margin: 0.5rem 0;
    border-radius: var(--border-radius);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.sign-up-container li:hover {
    transform: scale(1.02);
}

.sign-up-container li span {
    font-size: 1rem;
}

@media (max-width: 600px) {
    .sign-up-container h2, .sign-up-container h3 {
        font-size: 1.25rem;
    }

    .sign-up-container input, .sign-up-container button {
        font-size: 0.875rem;
    }

    .sign-up-container li span {
        font-size: 0.875rem;
    }
}