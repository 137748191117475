/* src/css/ProfilePage.css */

:root {
    --background-color: #ffffff;
    --text-color: #1c1c1e;
    --button-background-color: #007aff;
    --button-text-color: #ffffff;
    --button-hover-color: #0056b3;
    --border-radius: 16px;
}

.dark-mode {
    --background-color: #000000;
    --text-color: #ffffff;
    --button-background-color: #0a84ff;
    --button-text-color: #ffffff;
    --button-hover-color: #66aaff;
}

.profile-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: var(--background-color);
    min-height: 100vh;
    color: var(--text-color);
}

.profile-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.profile-header img, .profile-header svg {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
    border: 2px solid var(--text-color);
}

.profile-header h2 {
    margin: 0;
    font-size: 1.5rem;
    color: var(--text-color);
}

.profile-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    padding: 0;
}

.profile-menu button {
    background: var(--button-background-color);
    border: none;
    color: var(--button-text-color);
    cursor: pointer;
    padding: 15px;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
    width: 100%;
    font-size: 1rem;
    margin: 10px 0; /* Space between buttons */
    border-radius: var(--border-radius); /* Consistent border radius */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.profile-menu button:hover {
    background-color: var(--button-hover-color);
    transform: translateY(-2px); /* Slight lift on hover */
}

.profile-menu button:focus {
    outline: none; /* Remove outline */
    background-color: var(--button-hover-color);
}

   /* src/css/ProfilePage.css */
   .modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    margin: auto;
  }

  .overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }