:root {
    --padding: 10px;
    --margin: 15px;
    --border-radius: 8px;
    --font-size: 16px;
    --header-background-color: #f0f0f0;
    --text-color: #1e1e1e;
    --input-background-color: #fff;
    --input-text-color: #1e1e1e;
    --link-color: #007bff;
    --link-hover-color: #0056b3;
    --button-background-color: #007bff;
    --toggle-on: #4caf50;
    --background-color: #f0f0f0;
    --button-hover: #0056b3;
}

.dark-mode {
    --background-color: #1e1e1e;
    --text-color: #f0f0f0;
    --input-background: #333;
    --input-text-color: #f0f0f0;
    --button-background: #444;
    --button-hover: #666;
    --toggle-on: #4caf50;
}

.light-mode {
    --background-color: #f0f0f0;
    --text-color: #1e1e1e;
    --input-background: #fff;
    --input-text-color: #1e1e1e;
    --button-background: #007bff;
    --button-hover: #0056b3;
    --toggle-on: #4caf50;
}

.editor-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--background-color);
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: var(--text-color);
}

.editor-container h1 {
    text-align: center;
    margin-bottom: 20px;
    color: var(--button-background);
}

.editor-container label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: var(--text-color);
}

.editor-container input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #333;
    border-radius: 8px;
    background-color: var(--input-background);
    color: var(--input-text-color);
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
}

.editor-container button {
    width: 100%;
    padding: 12px;
    background-color: var(--button-background);
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    margin-top: 10px;
}

.editor-container button:hover {
    background-color: var(--button-hover);
    transform: translateY(-2px);
}

.back-button {
    display: block;
    text-align: center;
    margin-top: 15px;
    padding: 12px;
    background-color: var(--button-background);
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.back-button:hover {
    background-color: var(--button-hover);
    transform: translateY(-2px);
}

/* Toggle Switch */
.toggle-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 24px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: var(--toggle-on);
}

input:checked + .slider:before {
    transform: translateX(26px);
}