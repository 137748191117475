:root {
    --padding: 10px;
    --margin: 15px;
    --border-radius: 8px;
    --font-size: 16px;
    --header-background-color: #f0f0f0;
    --text-color: #1e1e1e;
    --input-background-color: #fff;
    --input-text-color: #1e1e1e;
    --link-color: #007bff;
    --link-hover-color: #0056b3;
    --button-background-color: #007bff;
}
.editor-container {
    max-width: 800px;
    margin: 0 auto;
    padding: var(--padding);
    background-color: var(--header-background-color);
    border-radius: var(--border-radius);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: var(--text-color);
}

.editor-container h1 {
    text-align: center;
    margin-bottom: var(--margin);
    color: var(--link-color);
}

.editor-container input {
    width: 100%;
    padding: var(--padding);
    margin-bottom: var(--margin);
    border: 1px solid #ccc; /* Add border for better visibility */
    border-radius: var(--border-radius);
    background-color: var(--input-background-color);
    color: var(--input-text-color);
    font-size: var(--font-size);
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.editor-container input:focus {
    border-color: var(--link-color); /* Highlight border on focus */
    outline: none; /* Remove default outline */
}

.editor-container button {
    width: 100%;
    padding: var(--padding);
    background-color: var(--link-color);
    color: var(--header-background-color);
    border: none;
    border-radius: var(--border-radius);
    font-size: var(--font-size);
    cursor: pointer;
    transition: background-color 0.3s;
}

.editor-container button:hover {
    background-color: var(--link-hover-color);
}

.react-mde {
    margin-bottom: var(--margin);
    background-color: var(--input-background-color);
    border-radius: var(--border-radius);
    color: var(--input-text-color);
}

.react-mde .mde-header {
    background-color: var(--header-background-color);
    color: var(--text-color);
}

.react-mde .mde-tabs button {
    background-color: var(--button-background-color);
    color: var(--text-color);
    border: none;
    border-radius: var(--border-radius);
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.react-mde .mde-tabs button:hover {
    background-color: var(--link-hover-color);
}

.react-mde .mde-textarea {
    background-color: var(--input-background-color);
    color: var(--input-text-color);
    border: none;
    border-radius: var(--border-radius);
}

.back-button {
    margin-top: var(--margin);
    padding: var(--padding);
    background-color: var(--button-background-color);
    color: white;
    border: none;
    border-radius: var(--border-radius);
    font-size: var(--font-size);
    cursor: pointer;
    transition: background-color 0.3s;
}

.back-button:hover {
    background-color: var(--link-hover-color);
}