:root {
    --background-color: #f5f5f7;
    --text-color: #1c1c1e;
    --card-background-color: #ffffff;
    --link-color: #007aff;
    --link-hover-color: #005bb5;
    --button-background-color: #007aff;
    --button-hover-color: #005bb5;
}

body.dark-mode {
    --background-color: #1c1c1e;
    --text-color: #f5f5f7;
    --card-background-color: #2c2c2e;
    --link-color: #0a84ff;
    --link-hover-color: #3a3a3c;
    --button-background-color: #0a84ff;
    --button-hover-color: #007aff;
}

body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    height: 100%;
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    min-height: 100vh;
    box-sizing: border-box;
}

h1, h2 {
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
}

section {
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
    background-color: var(--card-background-color);
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

li {
    margin: 10px 0;
}

a {
    color: var(--link-color);
    text-decoration: none;
    font-weight: 500;
    display: block;
    padding: 10px;
    border-radius: 8px;
    transition: background-color 0.3s ease;
}

a:hover {
    background-color: var(--link-hover-color);
    color: white;
}

button {
    background-color: var(--button-background-color);
    color: white;
    border: none;
    padding: 12px 32px; /* Increased padding for width */
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    margin: 10px;
    min-width: 120px; /* Set a minimum width */
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

button:hover {
    background-color: var(--button-hover-color);
    transform: translateY(-2px);
}

.auth-options {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.new-featured, .my-groups, .browse-groups {
    text-align: center;
}

/* Responsive Design */
@media (max-width: 600px) {
    section {
        padding: 15px;
    }

    button {
        padding: 8px 16px;
        font-size: 14px;
    }
}